import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./EditProfile.css";

import { useDispatch } from "react-redux";
import { triggerUserStyle } from "../Redux/actions/userActions";

export default function EditProfile() {
  const [userStyle, setUserStyle] = useState("");
  const dispatch = useDispatch();

  const changeStyle = () => {
    return dispatch(triggerUserStyle(userStyle));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changeStyle();
  };

  const handlechange = (event) => setUserStyle(event.target.value);

  return (
    <>
      <Form className="form-items">
        <div className="update-email">
          <Form.Group controlId="exampleForm.ControlInput1">
            <h5>
              <Form.Label>Update Email</Form.Label>
            </h5>
            <Form.Control type="email" placeholder="name@example.com" />
          </Form.Group>
        </div>
        <br />
        <div className="add-photo">
          <h5>Add User Photo</h5>
          <input type="file" />
        </div>
        <div className="about-me">
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <h5>About Me</h5>
            </Form.Label>
            <Form.Control className="text-area" as="textarea" rows={3} />
          </Form.Group>
        </div>
        <div className="add-style">
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>
              <h3>Add Some Style!</h3>
            </Form.Label>
            <h5>Add your styles in the form of a JSON object</h5>
            <Form.Control
              onChange={handlechange}
              //value={userStyle}
              as="textarea"
              rows={8}
              placeholder="hyello"
            />
            <h5>*Example Style*</h5>
            <p>
              backgroundColor:"blue", <br />
              border:"1px dashed red",
              <br />
              color:"yellow"
              <br />
            </p>
            <p className="importantText">
              (For multi word properties you must use "camelCase")
            </p>
            <Button
              onClick={handleSubmit}
              type="submit"
              value="submit"
              className="submit-button"
            >
              Submit
            </Button>
          </Form.Group>
        </div>
      </Form>
    </>
  );
}
