import { USER_STYLE } from "../actions/userActions";
// import { v4 } from "uuid";

const initialState = {
  backgroundColor: "white",
  color: "black",
  border: "1px solid grey",
};

export const userStyleReducer = (state = initialState, action) => {
  console.log(`{${action.payload}}`);
  // const newStyle = `{${action.payload}}`;

  switch (action.type) {
    case USER_STYLE:
      return {
        ...state,
        ...{
          backgroundColor: "blue",
          color: "yellow",
          border: " 1px dashed red",
        },
      };

    default:
      return state;
  }
};
