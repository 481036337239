import React from "react";
import { GoogleLogout } from "react-google-login";

const clientId = "199866396320-c6c9blvd8r978n8ft6ejs2nsii16prjv.apps.googleusercontent.com";

function Logout() {
  const onSuccess = () => {
    alert("Logout made successfully");
  };
  return (
    <div>
      <GoogleLogout clientId={clientId} buttonText="Logout" onLogoutSuccess={onSuccess}></GoogleLogout>
    </div>
  );
}

export default Logout;
