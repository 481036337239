import { NavLink } from "react-router-dom";
import "./Links.css";
import { BsChatSquareDots } from "react-icons/bs";
import { IconContext } from "react-icons";

export default function Links() {
  return (
    <nav className="NavbarItems">
      <img className="logo" src="icon_LI.png" alt="logo" />

      <h1 className="navbar-logo">CoderSpace</h1>

      <ul className="menu-items">
        <li>
          <NavLink to="/" className="home">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard" className="dashboard">
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="/messeges" className="message">
            <IconContext.Provider
              value={{
                style: {
                  fontSize: "30px",
                  color: "white",
                  marginLeft: "20px",
                  marginRight: "-50px",
                },
              }}
            >
              <div>
                <BsChatSquareDots />
              </div>
            </IconContext.Provider>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
