import React from "react";
import { Switch } from "react-router-dom";
import ConnectedRoute from "./ConnectedRoute";
import Home from "../screens/Home";
import NotFound from "../screens/NotFound";
import Dashboard from "../screens/Dashboard";
import editProfile from "../screens/EditProfile";
import messages from "../screens/Messages";
export default function Navigation() {
  return (
    <Switch>
      <ConnectedRoute exact path="/" redirectIfAuthenticated component={Home} />
      <ConnectedRoute
        exact
        isProtected
        path="/dashboard"
        component={Dashboard}
      />
      <ConnectedRoute path="/EditProfile" component={editProfile} />
      <ConnectedRoute path="/messeges" component={messages} />
      <ConnectedRoute path="*" component={NotFound} />
    </Switch>
  );
}
