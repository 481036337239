import React from "react";
import { GoogleLogin } from "react-google-login";

const clientId = "199866396320-c6c9blvd8r978n8ft6ejs2nsii16prjv.apps.googleusercontent.com";

function Login() {
  const onSuccess = (res) => {
    console.log("[Login Success] currentUser:", res.profileObj);
  };
  const onFailure = (res) => {
    console.log("[Login Failed] res:", res);
  };
  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "50px" }}
        isSignedIn={true}
      />
    </div>
  );
}

export default Login;
