export const USER_STYLE = "USER_STYLE";

export const triggerUserStyle = (userStyle) => {
  console.log({ userStyle });

  return {
    type: USER_STYLE,
    payload: userStyle,
  };
};
