import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import "./Messages.css";
export default function Messages() {
  return (
    <>
      <h2>Messages</h2>

      <div id="messagesDiv">
        <br />
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="messageBoxText">
              Drop a Message!
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            id="messageInput"
            as="textarea"
            aria-label="With textarea"
          />
          <br />
          <br />
          <Button>Submit</Button>
        </InputGroup>
      </div>
    </>
  );
}
