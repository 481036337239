import "./App.css";
import Navigation from "./components/Navigation";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Links from "./components/Links";
import useAuth from "./hooks/useAuth";
import { Spinner, Image } from "react-bootstrap";
import { useState } from "react";
import { Jumbotron } from "react-bootstrap";
import { useSelector } from "react-redux";

function App() {
  const [email, setEmail] = useState("");
  const auth = useAuth();

  let newUserStyle = useSelector(({ backgroundColor, color, border }) => {
    return {
      backgroundColor,
      color,
      border,
    };
  });
  console.log(newUserStyle);

  async function loginNow() {
    try {
      const UserEmail = email;
      console.log(UserEmail);
      await auth.login(UserEmail);
    } catch (err) {
      console.error(err);
      // maybe updat some sort of state to let the user know that it failed =]
    }
  }

  if (auth.loading || auth.loggingIn || auth.loggingOut) {
    // User is currently trying to log in or something..
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">It's Doing Something...LOL</span>
      </Spinner>
    );
  }

  const handleChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  return (
    <div className="App" style={newUserStyle}>
      <main id="appMain">
        {auth.loggedIn ? (
          <div>
            <Links />
            <div className="loginStatus">
              You are logged-in.
              <br />
              <button onClick={() => auth.logout()}>Logout</button>
              <Logout />
            </div>
          </div>
        ) : (
          <div className="login">
            <Image className="logo" src="icon_LI.png" alt="logo" />
            <div className="coder-text">CoderSpace</div>
            <div>
              <input
                className="text-box"
                onChange={handleChange}
                type="email"
                value={email}
              ></input>
              <br />
            </div>
            <button className="login-button" onClick={loginNow}>
              Login Now
            </button>
            <Login />
            <div id="loginTextDiv">
              <p className="motto">
                CoderSpace is a place for coders, made by coders.
              </p>
              <Jumbotron id="missionStatement">
                Remember back in the day when you could show someone your entire
                personality by sharing your Myspace page with them, or your
                Xanga. This was the golden age of customization. A simple coding
                mistake gave a world of teenagers the freedom to design
                themselves and opened up the world of CSS and HTML. A time When
                Foamy the squirrel and Salad fingers were the epitome of
                animation and Newgrounds.com was the place to be. Well, here at
                coderspace we want to bring that back and hopefully inspire
                tomorrow's developers and designers while igniting the nostalgia
                in your thirty-something-year-old hearts.To the new young
                generation...we shall teach you our ways. Rejoice, my friends,
                the age of customization is not lost, it was sleeping, and we
                aim to wake it up... RAWR XD, NUMA NUMA and such!
              </Jumbotron>
            </div>
          </div>
        )}

        <Navigation />
      </main>
    </div>
  );
}

export default App;
